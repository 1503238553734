<template>
  <div class="aboutus">
    <div class="bannerA">
      <img class="banimg" src="@/assets/images/banner1.png" />
    </div>
    <div class="bannerB">
      <div id="banner-top">
        <div class="header-box">深圳写手智能科技有限公司</div>
        <div class="header-str">
          Shenzhen writer Intelligent Technology Co., Ltd
        </div>
        <div class="fooder-str">
      

深圳写手智能科技公司成立于2020年6月，办公地点位于深圳南山区粤海街道科技园。主要从事垂直细分领域AIGC产品及配套专业服务的研发，包括AI语言模型、大型语料库与高精度训练集、多模态AI应用体系、大数据管理、智能在线文档等AIGC应用体系，以及合作律师、会计师、税务师、咨询师、笔杆子等专业人员的标准化服务产品，致力于打造智能内容与专业服务平台。现已推出“挖潜智库”、“小语AI绘画”等系列产品。
        </div>
      </div>
      
      <!-- <div class="fooder-box">
        <div class="header-hds">
          <div class="boxs1">
            <img src="@/assets/images/header-hds.png" alt="" />
          </div>
          <div class="header-content">
            <h1>成语 <img src="../../assets/images/idiom2.png" alt="" /></h1>
            <div class="contents">
              “成语”致力于为用户在内容创作时推荐出合适的、高品质的内容，自动标注内容出处来源，形成高效规范的引文尾注，以更好促进尊重原创、激发灵感。同时，提供金句、模板、智能网盘、一键采集、数据分析、溯源、OCR、划词成语等丰富的内容创作配套工具，是大学生、教师、公职人员、记者、编辑、文员、笔杆子等的必备神器。
            </div>
          </div>
        </div>
        <div class="header-hds">
          <div class="header-content">
            <h1>反抄袭 <img src="@/assets/images/fanchaoxi.png" alt="" /></h1>
            <div class="contents">
              “反抄袭”致力于为原创文字作品、图片、视频、文件等提供电子数据指纹，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。同时，提供智能网盘、版权收益、版权服务等一站式知识产权服务，并有执业律师提供标准化的流程公开、价格透明的咨询与实务服务，是原创作者、知识博主、杂志期刊与媒体机构、新媒体等的尚方宝剑。
            </div>
          </div>
          <div class="boxs1">
            <img src="@/assets/images/header-hds1.png" alt="" />
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="bannerC" v-show="transmits">
      <div>
        <div><img src="@/assets/images/wb1.png" alt="" /></div>
        <h3>微博</h3>
        <p>@写手智能科技</p>
      </div>
      <div>
        <div><img src="@/assets/images/xh1.png" alt="" /></div>
        <h3>小红书</h3>
        <p>@写手智能科技</p>
      </div>
      <div>
        <div><img src="@/assets/images/bd1.png" alt="" /></div>
        <h3>百度</h3>
        <p>@写手智能科技</p>
      </div>
      <div>
        <div><img src="@/assets/images/dy1.png" alt="" /></div>
        <h3>抖音</h3>
        <p>@写手智能科技</p>
      </div>
      <div class="tb" @click="transmit">
        <img src="@/assets/images/tb1.png" alt="" />
      </div>
    </div>
    <div class="bannerE" v-show="!transmits">
      <videoPlayerPage ref="videoPlayer" />
    </div> -->
    <!-- <div class="Fooder">
      <div class="logo-fooder">
        <img src="@/assets/images/logo2.png" alt="" />
      </div>
      <div class="banner-img"></div>
    </div> -->
  </div>
</template>

<script>

export default {
  name: "introduce",
  components: {
    // videoPlayerPage,
  },
  data() {
    return {
      activeName: "first",
      dialogVisible: false,
      bannerFirst: false,
      bannerSecond: false,
      bannerThird: false,
      transmits: true,
    };
  },

  methods: {
    transmit() {
      this.transmits = false;
      this.$refs.videoPlayer.videoplayer();
    },

    handleClose() {
      this.dialogVisible = false;
    },
    experience(val) {
      if (val == 1) {
        this.$router.push({
          name: "TraceTheSource",
        });
      } else if (val == 2) {
        this.$router.push({
          name: "Store",
        });
      } else if (val == 3) {
        this.$router.push({
          name: "disk",
        });
      } else if (val == 4) {
        this.$router.push({
          name: "copyrightIncome",
        });
      } else if (val == 5) {
        this.$router.push({
          name: "copyrightService",
        });
      } else {
        this.$router.push({
          name: "aboutus",
        });
      }
    },
    contactUs() {
      this.dialogVisible = true;
    },
    handleClick() {},
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: #f0f2f5;
}
.aboutus {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;

  .bannerA {
    width: 100%;
    height: 417px;
    position: relative;
    margin-top: -30px;
    margin-left: -1px;

    .banimg {
      width: 100%;
      height: 100%;
    }
  }
  .bannerB {
    margin-bottom: -1px;
    width: 100%;
    text-align: center;

    #banner-top {
      padding: 0 199px;

      box-sizing: border-box;
      .header-box {
        width: 100%;
        height: 44px;
        font-size: 46px;
        margin-top: 111px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
      .header-str {
        width: 100%;
        height: 27px;
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #999999;
        margin-top: 17px;
        line-height: 19px;
        opacity: 0.4;
      }
      .fooder-str {
        display: inline-block;
        width: 772px;
        height: 77px;
        margin-top: 38px;
        text-align: left;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
      }
      .header-hd {
        width: 100%;
        height: 724px;
        padding: 69px 148px 4.5% 149px;
        box-sizing: border-box;
        .header-left {
          float: left;
          position: relative;
          width: 50%;
          height: 540px;

          font-size: 16px;
          color: #888888;
          line-height: 34px;
          text-align: justify;
          .header-left1 {
            max-height: 450px;
            overflow-y: auto;
          }
          /*滚动条bai整体*/
          ::-webkit-scrollbar {
            width: 2px; /*滚动条宽度*/
          }
          ::-webkit-scrollbar-track {
            background-color: #fff; /*滑道全部*/
          }
          ::-webkit-scrollbar-track-piece {
            background-color: #fff; /*滑道*/
          }
          ::-webkit-scrollbar-thumb {
            background-color: #fafafa; /*滑动条表面*/
            border: none; /*滑动条边框*/
          }
          p {
            text-indent: 2em;
          }
          .header-my {
            cursor: pointer;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 159px;
            height: 56px;
            color: #fff;
            text-align: center;
            line-height: 56px;
            font-size: 22px;
            background-color: #ff6900;
          }
        }
        .header-right {
          float: right;
          width: 47%;
          height: 540px;
          img {
            width: 100%;
            height: calc(100% - 9px);
            margin-top: 9px;
          }
        }
      }
    }

    .fooder-box {
      width: 100%;
      padding: 59px 224px 110px 204px;
      box-sizing: border-box;
      .header-hds {
        display: flex;

        width: 100%;
        .boxs1 {
          width: 50%;
          img {
            width: 100%;
            height: 450px;
          }
        }

        .header-content {
          padding: 82px 71px 0px 60px;
          box-sizing: border-box;
          text-align: left;
          width: 50%;
          height: 450px;

          margin-bottom: 20px;
          background: #ffffff;
          box-shadow: 5px 5px 32px 0px rgba(155, 162, 173, 0.24);
          h1 {
            font-size: 34px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #000000;
            img {
              width: 75px;
              height: 75px;
              vertical-align: middle;
            }
          }
          .contents {
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 30px;
            max-height: 240px;
            overflow-y: auto;
          }
        }
      }
    }
  }
  .bannerE {
    width: 100%;
    height: 500px;
    color: #000;
    margin-bottom: 42px;
    .input_video {
      width: 100%;
      height: 100%;
      ::v-deep #video {
        width: 100%;
        height: 100%;
        .video-js,
        #vjs_video_3 {
          width: 100%;
          height: 500px;
          padding: 0;
          .vjs-tech {
            width: 100%;
            height: 500px;
            .vjs-text-track-display {
              width: 100%;
              height: 500px;
            }
          }
        }
      }
    }
  }
  .bannerC {
    position: relative;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 500px;
    color: #000;
    margin-bottom: 42px;
    background-image: url("../../assets/images/banner2.png");
    background-size: 100% 100%;
    padding: 148px 198px 152px 703px;
    box-sizing: border-box;
    img {
      width: 129px;
      height: 128px;
    }
    h3 {
      text-align: center;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      line-height: 30px;
    }
    p {
      text-align: center;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ff6900;
      // line-height: 30px;
      margin-top: 21px;
    }
    .tb {
      cursor: pointer;
      position: absolute;
      top: 50%;
      left: 17.5%;
      img {
        width: 82px;
        height: 82px;
      }
    }
  }
  .Fooder {
    text-align: center;
    width: 100%;
    height: 750px;
    padding: 43px 200px 96px;
    box-sizing: border-box;
    img {
      width: 230.3px;
      height: 80.8px;
    }
    .banner-img {
      margin-top: 73px;
      width: 100%;
      height: 415px;
      background: url("../../assets/images/011.png") no-repeat;
      background-size: 100%;
    }
  }
}
</style>
